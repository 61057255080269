import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2023-06-07-park/body-landing"

const ParkView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="More STI Patients May Be Heading Your Way: An Update on CDC STI Guidelines"
				videoDisplayTitle="More STI Patients May Be Heading Your Way: An Update on CDC STI Guidelines"
				vimeoLink="https://player.vimeo.com/video/847060254?h=f3babe8f18" 
				placeholderImage="2023-06-07-park-webinar-replay.jpg"
				localStorageID="2023-06-07-park-webinar-replay"
				pageSlug="/webinar-replay/2023-06-07-park/"
			/>
			<BodyLanding pageName="download" localStorageID="2023-06-07-park-webinar-replay" />
		</React.Fragment>
	)
}

export default ParkView

export const Head = () => (
  <Seo
  title="Webinar: More STI Patients May Be Heading Your Way: An Update on CDC STI Guidelines"
  description="More STI Patients May Be Heading Your Way: An Update on CDC STI Guidelines"
  image="/meta/webinar-park-2023-06-07.jpg"
/>
)
